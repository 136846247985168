const fullDateFormat = [
    { year: "numeric", month: "short", day: "numeric" },
    { hour: "numeric", minute: "numeric", hour12: true }
];
const dateTimeConstants = {
    filteredOutPartType: "literal",
    defaultDelimiter: " | ",
    defaultDateOrder: {
        month: 0,
        day: 1,
        year: 2
    },
    mockMonth: "8",
    mockDay: "17",
    mockYear: "2003",
    mockDate: new Date("Aug 17 2003")
};

class DateTimeFormatter {
    constructor(locale) {
        this.locale = locale;
        this.dateOrdering = { }; // initialize as empty object, as we will be caching the date ordering here
    }

    getShortDate(date) {
        return this.getCustomDateTime(date);
    }

    getFullDate(date, delimiter = dateTimeConstants.defaultDelimiter) {
        const firstHalfOfFullDate = this.getCustomDateTime(date, fullDateFormat[0]);
        const secondHalfOfFullDate = this.getCustomDateTime(date, fullDateFormat[1]);

        return firstHalfOfFullDate + delimiter + secondHalfOfFullDate;
    }

    getCustomDateTime(date = new Date(), options) {
        // date may be a utc value or date string in which case we need to format it as new Date object
        if (typeof date === "string" || typeof date === "number") {
            date = new Date(date);
        }
        const formatter = new Intl.DateTimeFormat(this.locale, options);
        try {
            return formatter.format(date); // this will i18n'ize the date
        }
        catch (error) {
            return "";
        }
    }

    // As of 03/27/2019 Internet Explorer doesn't support formatToParts so we created getDefaultDateOrdering fallback method
    // If you want the polyfill (additional code bloat we decided against using), here it is: https://github.com/tc39/proposal-intl-formatToParts
    // Update 05/22/2019 IE *and* Edge 17 don't support formatToParts and polyfill doesn't actually exist so we're using getDefaultDateOrdering as a fallback
    getOrderedDateParts(options) {
        // check if this.dateOrdering is locally cached
        if (Object.keys(this.dateOrdering).length === 0) {
            const formatter = new Intl.DateTimeFormat(this.locale, options);
            // formatToParts are unsupported in IE, Edge below 18, and Opera so we need to default to getDefaultDateOrdering()
            this.dateOrdering = this.getDefaultDateOrdering();
            if (formatter.formatToParts) {
                // use formatted date parts to extract year/month/day and get the date ordering
                const parts = formatter.formatToParts(new Date());
                const filteredParts = parts.filter((part) => (part.type !== dateTimeConstants.filteredOutPartType));
                if (filteredParts.length === 3) {
                    filteredParts.forEach((part, index) => {
                        this.dateOrdering[part.type] = index;
                    });
                }
            }
        }
        return this.dateOrdering;
    }

    // Supporting getOrderedDateParts method, we can use getDefaultDateOrdering in all browsers to get date ordering for year/month/day
    getDefaultDateOrdering() {
        const dateOrdering = dateTimeConstants.defaultDateOrder;
        // parse some date and get the index of year/month/day
        const dateToParse = this.getShortDate(dateTimeConstants.mockDate);
        // sort the indices by their formatted order (ascending indices)
        let dateTypes = [
            { type: "year", index: dateToParse.indexOf(dateTimeConstants.mockYear) },
            { type: "month", index: dateToParse.indexOf(dateTimeConstants.mockMonth) },
            { type: "day", index: dateToParse.indexOf(dateTimeConstants.mockDay) }
        ];
        // ensure that we have all 3 date indices represented in the short date for current locale
        if (dateTypes.some((dateType) => dateType.index === -1)) {
            return dateTimeConstants.defaultDateOrder;
        }
        // sort array of date types by their formatted index ascending (e.g., If 'year' appears first in format it will be first element of array)
        dateTypes = dateTypes.sort((a, b) => {
            return a.index - b.index;
        });
        // consumers of this method will need each date type to have a corresponding value of 0/1/2 based on its sorted array position.
        dateTypes.forEach((dateObject, i) => {
            dateOrdering[dateObject.type] = i;
        });
        return dateOrdering;
    }
}

export function getDateTimeFormatter(locale) {
    return new DateTimeFormatter(locale);
}
