import { LocalStorage } from 'Roblox';
import IntlMessageFormat from '../vendors/core';
import defaultLocale from '../vendors/en';
import getLanguageSensitiveStringComparer from './languageSensitiveStringComparer';
import { isAsianLanguageSelected, getFormattedDateWithOptionalModifier, getMonthsListWithOptionalModifier } from './supportedLocales';
import { getDateTimeFormatter } from './dateTimeFormatter';

IntlMessageFormat.__addLocaleData(defaultLocale);
IntlMessageFormat.defaultLocale = 'en';

const intl = function(localeCode, timeZone, currencyCode) {
    var localStorageKey = "RobloxLocaleCode";

    var isLocalStorageAvailable = false;
    // localStorage is undefined when intl.js is loaded before the dom (i.e., for service worker)
    if (typeof (localStorage) !== 'undefined') {
        isLocalStorageAvailable = LocalStorage
            ? LocalStorage.isAvailable()
            : localStorage && localStorage.getItem && localStorage.setItem;
    }
    if (!localeCode) {
        var metaTag = document.querySelector('meta[name="locale-data"]');
        // try to get it from meta tag
        if (metaTag && metaTag.dataset && metaTag.dataset.languageCode) {
            localeCode = metaTag.dataset.languageCode;
        }
        else if (metaTag) {
            var languageCode = metaTag.getAttribute('data-language-code');
            if (languageCode) {
                localeCode = languageCode;
            }
        }

        // try to get it from localStorage
        if (isLocalStorageAvailable && !localeCode) {
            localeCode = localStorage.getItem(localStorageKey);
        }

        // Chrome 79 introduced an update to not support custom locales. This breaks for calls to the browsers Intl library. 
        if (localeCode === 'zh_cjv') {
            localeCode = 'zh_cn';
        }

        if (!localeCode) {
            localeCode = 'en-us';
        }

        // Convert from Roblox to ISO Format
        localeCode = localeCode.replace(/_/g, "-");
    }

    this.locale = localeCode;
    this.defaultLocale = [localeCode];
    this.localeApiUrl = metaTag && metaTag.dataset && metaTag.dataset.localeApiUrl;
    this.timeZone = timeZone || 'America/Los_Angeles';
    this.currency = currencyCode || 'USD';
    this.monthsList = {};
    this.weekdaysList = {};

    // persist in localstorage
    if (isLocalStorageAvailable) {
        localStorage.setItem(localStorageKey, this.locale);
    }

    // setup language sensitive compare function
    this.langSensitiveCompare = getLanguageSensitiveStringComparer(this.locale);

    // setup supported locale helper functions
    this.isAsianLanguage = isAsianLanguageSelected;
    this.getFormattedDateString = getFormattedDateWithOptionalModifier;
    this.getMonthsI18n = getMonthsListWithOptionalModifier;

    // setup date formatter
    this.getDateTimeFormatter = function() {
        return getDateTimeFormatter(this.locale);
    };
};

intl.prototype.getLocale = function() {
    return this.locale;
};

intl.prototype.getRobloxLocale = function () {
    return this.locale.replace(/-/g, "_");
};

intl.prototype.getLocaleApiUrl = function() {
    return this.localeApiUrl;
};

intl.prototype.getTimeZone = function() {
    return this.timeZone;
};

intl.prototype.getCurrency = function () {
    return this.currency;
}

/*
 * Get Localized Message
 * @param message {string} An interpolatable string based on ICU standards to support pluralization (if needed)
 * @param params {object} key value map of values needed for interpolation
 * @returns string 
 */

intl.prototype.f = function (message, params, options) {

    if (typeof message !== "string") {
        throw new TypeError("'message' must be a string");
    }

    var formatter = new IntlMessageFormat(message, this.locale, options);
    return formatter.format(params);
};

/*
 * Get localized date string
 * @param {date} dateObj
 * @param {string|object} options. When passing string use "full" or "short", otherwise 
 * pass options object as defined for Intl.DateTimeFormat
 * @returns {string} 
 */

intl.prototype.d = function (dateObj, options) {

    if (typeof dateObj !== "object" || !Date.prototype.isPrototypeOf(dateObj)) {
        throw new TypeError("'dateObj' must be a JavaScript date object");
    }

    var formatOptions;
    var definedFormats = {
        short: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        },
        full: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        },
        time: {
            hour: '2-digit',
            minute: '2-digit'
        }
    };

    if (typeof options === "string" || options === undefined) {
        formatOptions = definedFormats[options] || definedFormats['short']; // default to short
    }
    else if (typeof options === "object") {
        formatOptions = options;
    }
    else {
        throw new TypeError("'options' must be either of type string or object based on Intl.DateTimeFormat");
    }

    try {
        return new Intl.DateTimeFormat(this.defaultLocale, formatOptions).format(dateObj);
    }
    catch (e) {
        //fallback to toLocaleString
        return dateObj.toLocaleString(this.defaultLocale);
    }
}

/*
 * Get localized number string
 * @param {number} number
 * @param {string|object} options. When passing string use "currency" or "percent" otherwise 
 * pass options object as defined for Intl.NumberFormat
 * @returns {string} 
 */

intl.prototype.n = function (number, options) {
    if (isNaN(number)) {
        throw new TypeError("The argument 'number' must be of type number");
    }

    var formatOptions;
    var definedFormats = {
        currency: {
            style: "currency",
            currency: this.currency
        },
        percent: {
            style: "percent",
            maximumFractionDigits: 2
        },
        decimal: {
            style: "decimal",
            maximumFractionDigits: 2
        }
    };

    if (typeof options === "string" || options === undefined) {
        formatOptions = definedFormats[options] || definedFormats['decimal']; // default to decimal    
    }
    else if (typeof options === "object") {
        formatOptions = options;
    }
    else {
        throw new TypeError("'options' must be of type string or object based on Intl.NumberFormat");
    }

    try {
        return new Intl.NumberFormat(this.defaultLocale, formatOptions).format(number);
    }
    catch (error) {
        return number;
    }
}

/*
 * Get localized list of months
 * @param {monthFormat} string - valid values are based on official Intl.DateTimeFormat month options
 * For example "short" for Jan, Feb,... and "long" for January, February, ...
 * @returns {array of objects} 
 */

intl.prototype.getMonthsList = function (monthFormat) {
    var validFormats = ["numeric", "2-digit", "narrow", "short", "long"];
    var format = (validFormats.indexOf(monthFormat) > -1) ? monthFormat : "short"; // default to short
    var pivotYear = 2017;
    var that = this;

    // use pre-built list if it exists
    if (this.monthsList[format] && this.monthsList[format].length > 0) {
        return this.monthsList[format];
    }

    // Build a list of months by dynamically creating dates for each month - use any year for pivot
    var months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(function (index) {
        return new Date(pivotYear, index - 1);
    });

    this.monthsList[format] = months.map(function (item, index) {
        return {
            value: index + 1,
            name: Intl.DateTimeFormat(that.locale, {
                month: format
            }).format(item)
        };
    });
    return this.monthsList[format];
}

/*
 * Get localized list of weekdays
 * @param {dayFormat} string - valid values are based on official Intl.DateTimeFormat 'weekday' options
 * For example "short" for Mon, Tue,... and "long" for Monday, Tuesday ...
 * @returns {array of objects} 
 */

intl.prototype.getWeekdaysList = function (dayFormat) {
    var validFormats = ["narrow", "short", "long"];
    var format = (validFormats.indexOf(dayFormat) > -1) ? dayFormat : "short"; // default to short
    var pivotYear = 2017;
    var pivotMonth = 4; // May
    var that = this;

    // use pre-built list if it exists
    if (this.weekdaysList[format] && this.weekdaysList[format].length > 0) {
        return this.weekdaysList[format];
    }

    // Build a list of weekdays by dynamically creating dates for each day - using May 2017 as pivot since Monday starts on 1st
    var days = [1, 2, 3, 4, 5, 6, 7].map(function (index) {
        return new Date(pivotYear, pivotMonth, index);
    });

    this.weekdaysList[format] = days.map(function (item, index) {
        return {
            value: index + 1,
            name: Intl.DateTimeFormat(that.locale, {
                weekday: format
            }).format(item)
        };
    });
    return this.weekdaysList[format];
}

export default intl;