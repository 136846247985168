var asianLocaleList = [
    'zh-cn',
    'zh-tw',
    'ko-kr',
    'ja-jp'
];

var localeRequiringSpacesList = [
    'zh-tw'
];

var isAsianLanguage = function (locale) {
    // index 0 should return true and index -1 should return false, so adding 1 will return the correct truthy/falsey value
    var isLocaleAnAsianLanguage = asianLocaleList.indexOf(locale) + 1;
    return !!(locale && isLocaleAnAsianLanguage);
};

var getFormattedDate = function (context, date, dateModifier) {
    if (isAsianLanguage(context.locale) && dateModifier) {
        if (localeRequiringSpacesList.indexOf(context.locale) >= 0) {
            return date + " " + dateModifier; // add a space between date and date modifier
        }
        return date + dateModifier; // no space between date and date modifier
    }
    return date;
};

var getMonths = function (context, monthFormat, modifier) {
    var validFormats = ["numeric", "2-digit", "narrow", "short", "long"];
    var format = (validFormats.indexOf(monthFormat) > -1) ? monthFormat : "short"; // default to short
    var pivotYear = 2017;

    // use pre-built list if it exists
    if (context.monthsList[format] && context.monthsList[format].length > 0) {
        return context.monthsList[format];
    }

    // Build a list of months by dynamically creating dates for each month - use any year for pivot
    var months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(function (index) {
        return new Date(pivotYear, index - 1);
    });

    context.monthsList[format] = months.map(function (item, index) {
        var monthToReturn;
        if (isAsianLanguage(context.locale)) {
            monthToReturn = {
                value: index + 1,
                name: getFormattedDate(context, index + 1, modifier)
            }
        } else {
            monthToReturn = {
                value: index + 1,
                name: Intl.DateTimeFormat(context.locale, {
                    month: format
                }).format(item)
            };
        }
        return monthToReturn;
    });
    return context.monthsList[format];
};

export function isAsianLanguageSelected () {
    return isAsianLanguage(this.locale);
}

export function getFormattedDateWithOptionalModifier(date, dateModifier) {
    return getFormattedDate(this, date, dateModifier);
}

export function getMonthsListWithOptionalModifier(monthFormat, modifier) {
    return getMonths(this, monthFormat, modifier);
}
